import React from "react";
import "./social.css";
import { FaTelegramPlane } from "react-icons/fa";
import { TbBrandTwitter, TbBrandDiscord } from "react-icons/tb";

const Social = () => {
  return (
    <div className="ico">
      <div className="aler">
        <ul>
          <li className="iconlist">
            <a
              style={{ color: "white" }}
              href="https://t.me/DBKRoyal/"
              target="_blank"
            >
              <FaTelegramPlane className="iconic-side" />
            </a>
          </li>
          <li className="iconlist">
            <a
              style={{ color: "white" }}
              href="https://x.com/DiamondDaoNFT"
              target="_blank"
            >
              <TbBrandTwitter className="iconic-side" />
            </a>
          </li>
          {/* <li className="iconlist">
            <a
              style={{ color: "white" }}
              href="https://twitter.com/DiamondWomanNFT?s=20&t=9k_VRiJdk-hIweJlfvnq3g"
              target="_blank"
            >
              <FiGithub className="iconic-side" />
            </a>
          </li> */}
          {/* <li className="iconlist">
            <a
              style={{ color: "white" }}
              href="https://twitter.com/DiamondWomanNFT?s=20&t=9k_VRiJdk-hIweJlfvnq3g"
              target="_blank"
            >
              <BsMedium className="iconic-side" />
            </a>
          </li> */}
          <li className="iconlist">
            <a
              style={{ color: "white" }}
              href="https://discord.com/invite/zJRpUZepnV"
              target="_blank"
            >
              <TbBrandDiscord className="iconic-side" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Social;
